.position-heading{
    width: 100%;
    max-width: 400px;
}
.position-center{
    margin: auto;
    width: 60%;
    /* border: 3px solid #73AD21; */
    padding: 10px;
    text-align: center;
}

.position-data{
    font-size: 18px;
    font-family: 'PT Serif', serif;
}
.position-createdby{
    font-size: 1.1rem;
    font-family: 'PT Serif', serif;
}
.buttons-position{
    display: flex;
    justify-content: space-around;
}