[data-amplify-authenticator][data-variation=modal] {
  background: linear-gradient(217deg, rgba(151, 235, 228, 0.8), rgba(255,0,0,0) 70.71%),
            linear-gradient(127deg, rgb(178, 233, 190), rgba(0,255,0,0) 70.71%),
            linear-gradient(336deg, rgb(202, 192, 162), rgba(0,0,255,0) 70.71%) !important;
}

body {
  /* background: linear-gradient(90deg,rgb(137, 233, 178),rgb(255, 255, 255),rgb(199, 184, 137)); */
   background: linear-gradient(217deg, rgba(162, 234, 239, 0.8), rgba(255,0,0,0) 70.71%),
             linear-gradient(127deg, rgb(233, 228, 178), rgba(0,255,0,0) 70.71%),
             linear-gradient(336deg, rgb(202, 186, 165), rgba(0,0,255,0) 70.71%);
 }