.footer{
    text-decoration: none;
    color: black;
}
.footer-wrap{
    display:flex;
    justify-content: center;
}
.footer-cr{
    margin-top: 4px;
}