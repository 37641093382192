/* NAVBAR */
* {
    box-sizing: border-box;
  }
  
body {
    margin: 0;
}

.site-title {
    font-size: 2rem;
}

.nav {
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;  
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.header-nav a {
    color: white;
    text-decoration: none;
}
.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.nav li.active {
    background-color: #555;
}

.nav li:hover {
    background-color: #777;
}
.nav svg:hover {
    background-color: #777;
}
.nav-button:hover {
    background-color: #333 !important;
}
.font-upload{
    margin: 8px;
}
.upload-icon:hover .font-upload {
    color: rgb(243, 188, 129);
}

.font-upload-logo:hover{
    color: rgb(243, 188, 129);
}

.sign-out{
    color: white;
    background-color: #333;
    border-color: white;
    margin-bottom: 2px;
    margin-top: 10px;
    margin-left: 8px;
    font-size: 1.25rem !important;
}

.sign-out:hover{
    background-color: #777;
}

.text-nav{
    font-size: 1.20rem;
    margin-top: 6px;
}
.logo{
    position: relative;
}
  
.header-nav{
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: center;
}
.text-nav:hover{
    color: white;
}
@media (max-width: 600px) {
    .nav-list {
      flex-direction: column;
      text-align: center;
    }
    .nav{
        padding-left: 0;
        /* flex-grow: 8; */
    }
    .header-nav{
        justify-content: space-between;
    }
    .menu-btn{
        text-align: end;
        width: 270px;
      }
    .nav a {
        display: inline !important;
    }
    .sign-out{
        margin-left: 0;
    }
  }
  @media (max-width: 503px) {
    .menu-btn{
        text-align: end;
        width: 250px;
      }
  }
  @media (max-width: 475px) {
    .menu-btn{
        width: 230px;
      }
  }
  @media (max-width: 450px) {
    .menu-btn{
        width: 190px;
      }
  }
  @media (max-width: 425px) {
    .menu-btn{
        width: 180px;
      }
  }
  @media (max-width: 405px) {
    .menu-btn{
        width: 160px;
      }
  }
  @media (max-width: 485px) {
    .menu-btn{
        width: 140px;
      }
  }